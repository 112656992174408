import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import Swiper from 'swiper9/swiper-bundle.min';
import 'swiper9/swiper.scss';
export default {
  name: 'LandingOffersSlider',
  props: {
    items: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      offerSlider: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initOfferSlider();
    });
  },
  beforeDestroy: function beforeDestroy() {
    try {
      if (this.offerSlider) {
        this.offerSlider.destroy();
        this.offerSlider = null;
      }
    } catch (e) {
      console.error("Destroy slider: ".concat(e), this);
    }
  },
  methods: {
    initOfferSlider: function initOfferSlider() {
      var options = {
        speed: 1000,
        slidesPerView: 'auto',
        spaceBetween: 24,
        fadeEffect: {
          crossFade: true
        },
        allowTouchMove: false,
        direction: 'horizontal',
        breakpoints: {
          0: {
            allowTouchMove: true
          },
          1024: {
            allowTouchMove: false
          }
        }
      };
      this.offerSlider = new Swiper(this.$refs.offerSlider, options);
    }
  }
};