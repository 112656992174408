import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.items.length > 0 ? _c('div', {
    ref: "offerSlider",
    staticClass: "swiper-container",
    class: _vm.$style.offerSlider
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide",
      class: _vm.$style.slide
    }, [_c('div', {
      class: _vm.$style.offerItem
    }, [_c('div', {
      class: _vm.$style.offerItemTitle
    }, [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]), _vm._v(" "), _c('div', {
      class: _vm.$style.offerItemBottom
    }, [_c('div', {
      class: _vm.$style.offerItemTextWrapper
    }, [_c('div', {
      class: _vm.$style.offerItemText,
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    }), _vm._v(" "), item.note ? _c('div', {
      class: _vm.$style.offerItemFootnote
    }, [_vm._v("\n              " + _vm._s(item.note) + "\n            ")]) : _vm._e()]), _vm._v(" "), _c('div', {
      class: _vm.$style.offerItemIcon
    }, [item.image.aws ? _c('img', {
      attrs: {
        "src": item.image.aws,
        "alt": item.title
      }
    }) : _vm._e()])])])]);
  }), 0)]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };