var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.partnerSlider
  }, [_c('div', {
    ref: "partnerSlider",
    staticClass: "swiper-container",
    class: _vm.$style.slider
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide",
      class: _vm.$style.slide
    }, [_c('div', {
      class: _vm.$style.partnerItem
    }, [_c('img', {
      attrs: {
        "src": item.image.aws,
        "alt": ""
      }
    })])]);
  }), 0)]), _vm._v(" "), _c('div', {
    class: _vm.$style.bottom
  }, [_c('div', {
    ref: "pagination",
    staticClass: "swiper-pagination swiper-pagination-progressbar",
    class: _vm.$style.pagination
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.arrows
  }, [_c('div', {
    ref: "prevEl",
    class: [_vm.$style.arrow, _vm.$style.arrowPrev]
  }, [_c('IconLandingPartnerSliderArrow', {
    attrs: {
      "direction": "left"
    }
  })], 1), _vm._v(" "), _c('div', {
    ref: "nextEl",
    class: [_vm.$style.arrow, _vm.$style.arrowNext]
  }, [_c('IconLandingPartnerSliderArrow', {
    attrs: {
      "direction": "right"
    }
  })], 1)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };