import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import Swiper from 'swiper9/swiper-bundle.min';
import 'swiper9/swiper.scss';
import IconLandingPartnerSliderArrow from '@/components/icons/IconLandingPartnerSliderArrow.vue';
export default {
  name: 'LandingPartnerSlider',
  components: {
    IconLandingPartnerSliderArrow: IconLandingPartnerSliderArrow
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      partnerSlider: undefined
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initPartnerSlider();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.partnerSlider) {
      this.partnerSlider.destroy();
    }
  },
  methods: {
    initPartnerSlider: function initPartnerSlider() {
      var _this$$refs, _this$$refs2, _this$$refs3, _this$$refs4;
      var options = {
        speed: 1000,
        slidesPerView: 'auto',
        spaceBetween: 22,
        fadeEffect: {
          crossFade: true
        },
        allowTouchMove: true,
        direction: 'horizontal',
        watchSlidesProgress: true,
        navigation: {
          prevEl: ((_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.prevEl) === null || _this$$refs === void 0 ? void 0 : _this$$refs.$el) || ((_this$$refs2 = this.$refs) === null || _this$$refs2 === void 0 ? void 0 : _this$$refs2.prevEl) || false,
          nextEl: ((_this$$refs3 = this.$refs) === null || _this$$refs3 === void 0 || (_this$$refs3 = _this$$refs3.nextEl) === null || _this$$refs3 === void 0 ? void 0 : _this$$refs3.$el) || ((_this$$refs4 = this.$refs) === null || _this$$refs4 === void 0 ? void 0 : _this$$refs4.nextEl) || false,
          hiddenClass: 'is-hidden',
          disabledClass: 'is-disabled'
        },
        pagination: {
          el: this.$refs.pagination || false,
          type: 'progressbar',
          clickable: true
        },
        breakpoints: {
          0: {
            spaceBetween: 16
          },
          1024: {
            spaceBetween: 22
          }
        }
      };
      this.partnerSlider = new Swiper(this.$refs.partnerSlider, options);
    }
  }
};